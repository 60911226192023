body {
    font-size:      18px;
}
img {
    max-width:      100%;
    margin:         0;
    padding:        0;
    border-style:   none;
}
.overlay {
    width:          100%;
    height:         100%;
    position:       absolute;
    top:            0;
    left:           0;
    background:   rgba(0,0,0,.125);
}
.masthead {
    position:              relative;
    background-repeat:     no-repeat;
    background-size:       cover;
    background-repeat:     no-repeat;
    background-position:   center center;
    margin-bottom:         0;
    padding-top:           10%;
    border-radius:         0;
}
.stern {
    position:              relative;
    background-size:       cover;
    background-repeat:     no-repeat;
    background-position:   center center;
    margin-bottom:         0;
    border-radius:         0;
}
.bg-image {
    background-size:       cover;
    background-repeat:     no-repeat;
    background-position:   center center;
    margin-bottom:         0;
    border-radius:         0;
}
.text-header {
    font-family:           'Roboto', sans-serif;
}
.learn-more {
    padding:        .5rem 2rem;
    border-radius:  20px;
}
.primary-button-bg-color {
    background:  #00b0fd;
    border:        1px solid #00b0fd;
    &:hover {
        background: #00afff;
        border:       1px solid #00afff;
    }
}
.primary-text-color {
    color:  #6C748E;
}
.secondary-text-color {
    color:  #64666E;
}
.section-text-bg {
    background: transparent linear-gradient(177deg, #FFFFFF 0%, #EDEEFF 100%) 0% 0% no-repeat padding-box;
}

// Cards
#nav-tab {
    & > div {
        & > div.card {
            border:         0;
            background:     none;
            margin-bottom:  0 !important;
            @media only screen and (max-width: 576px) {
                padding:        0 !important;
                .nav-item {
                    // margin-bottom:     5%;
                    min-height:     100% !important;
                    height:         100% !important;
                    margin-bottom:  2%;
                }
                .active.hover-card {
                    .overlay-container {
                        height:     39%;
                        h4 {
                            left:           9%;
                            bottom:         60%;
                            width:          80%;
                            margin-bottom:  -5%;
                            font-size:      14px;
                        }
                    }
                    .test-div {
                        h6 {
                            font-size:          14px;
                            padding-bottom:     5% !important;
                        }
                    }
                }
            }
            // special CSS for the range between 576px and 768px because it's special >:|
            @media only screen and (max-width: 576px) and (min-width: 768px) {
                .nav-item {
                    margin-bottom:  20%;
                }
            }
        }
    }
    ul.nav-tabs {
        border-bottom:  0;
        .nav-item {
            .hover-card {
                width: 100%;
                height: 75%;
            }
        }
        @media only screen and (max-width: 1000px) {
            h4 {
                font-size:      11px;
                width:          100%;
            }
            h5 {
                padding-top:    45% !important;
                font-size:      10px;
            }
        }
        @media only screen and (max-width: 480px) {
            h4 {
                width:          100%;
                margin-bottom:  -20%;
                font-size:      20px;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding:    5%;
    }
}
.overlay-container {
    position:           absolute;
    top:                0;
    left:               0;
    width:              100%;
    height:             100%;
    display:            flex;
    flex-direction:     column;
    justify-content:    space-evenly;
    transition:         0.5s ease-in;
    background:         #ffffff;
    border-radius:      25px;
    box-sizing:         border-box;
    padding-top:        10%;
    z-index:            99;
    text-align:         left;
    h4 {
        color:          #4e4e4e;
        position:       absolute;
        width:          100%;
        font-size:      1.25rem;
        bottom:         50%;
        margin-bottom:  -25%;
        text-align:     center;
        left:           50%;
        margin-left:    -50%;
        transition:     0.5s ease-in;
    }
    img {
        position:               absolute;
        width:                  25%;
        left:                   50%;
        margin-left:            -12.5%;
        bottom:                 50%;
        margin-bottom:          -12.5%;
        transition:             0.5s ease-in;
        transition-delay:       0.15s;
    }
}
.test-div {
    h6 {
        // font-size:      18px;
        @media only screen and (max-width: 1400px) {
            // font-size:     14px;
        }
        @media only screen and (max-width: 1000px) {
            // font-size:     9px;
        }
    }
}
.hover-card:hover {
    .overlay-container {

        img {
            transform:  scale(1.1);
        }
        h4 {
            transition:         .5s ease-in;
            transition-delay:   .15s;
            transform:          scale(1.1);
        }
    }
}

.active.hover-card {
    .overlay-container {
        // transform:      scale(.83333) translateY(-50px);
        flex-direction:             row;
        justify-content:            start;
        height:                     35%;
        padding-top:                0;
        background:                 #ffffff;
        border:                     1px solid #dee2e6;
        border-radius:              25px; 
        border-width:               0 0 1px 0;         
        img {
            position:               absolute;
            transform:              translateX(0);
            width:                  20%;
            left:                   0;
            margin-left:            2%;
            transition:             0.5s ease-in;
            bottom:                 50%;
            margin-bottom:          -10%;
        }
        h4 {
            position:           absolute;
            width:              80%;
            left:               0;
            bottom:             50%;
            margin-left:        20%;
            margin-bottom:      -3%;
            color:            #4e4e4e;
            // font-size:          16px;
            text-align:         center;
            // transition:         .5s ease-in;
            // transition-delay:   .15s;
            @media only screen and (max-width: 1000px) {
                margin-left:    7%;
                margin-bottom:  -5%;
            }
            @media only screen and (max-width: 768px) {
    
            }
        }
    }
}


.banner {
    @media only screen and (max-width: 768px) {
        img {
            width:  80% !important;
        }
    }
}


.modal-header {
    .close {
        transition:     0.2s ease-in;
    }
}

// About
.mar-bot40 {
    margin-bottom:      40px;
}

// Careers
.openings-wrapper {
    max-width:      85%;
    margin:         0 auto;
    padding:        7% 0;
    @media only screen and (max-width: 480px) {
        margin:         0 auto !important;
        padding:        7% 0;
        max-width:      100%;    
    }
}
.department {
    margin-bottom:  40px;
}
li.listing {
    display:            flex;
    justify-content:    space-between;
}
h4.listing, ul.listing {
    margin-bottom:      20px;
    @media only screen and (max-width: 480px) {
        margin-bottom:  16px;
    }
}
.listing {
    list-style-type:    none;
    color:            #363945;
    font-size:          21px;
    font-weight:        500;
    margin:             0;
    @media only screen and (max-width: 480px) {
        text-align:     center;
        font-size:      18px;
    }
    ul {
        list-style-type: disc;
        @media only screen and (max-width: 480px) {
            padding:    0;
        }
    }
    a {
        margin:          0;
        color:         #1eb1fa;    
        font-size:       20px;
        font-weight:     400;
        @media only screen and (max-width: 480px) {
            font-size:   14px;
        }
    }
    p {
        margin:         0;
        color:        #888888;
        font-size:      20px;
        font-weight:    500;
        @media only screen and (max-width: 480px) {
            font-size:  14px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .hide-mobile {
        display:       none;
    }
}


@keyframes bounce {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-100px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}