footer {
    background:    #313139;
    .pad-section, 
    .pad-section-md {
        padding:    40px 20px 0 20px; 
        @media only screen and (max-width: 768px) {
           padding: 80px 0;
        }
    }
    .social-links {
        display:            flex;
        justify-content:    flex-start;
       li {
           border-radius:   50%;
           .footer-social-link {
              
           }
       }
    }
    @media only screen and (max-width: 768px) {
        .text-right {
            text-align:     unset !important;
        }
    }
}