.navbar-toggler {
    border: 0;
    &:focus, 
    &:active {
        outline: none;
        box-shadow: none;
    }
}
.vertical-divider {
    height:         40px;
    width:          2px;
    background:    rgba(255, 255, 255, .5);
    margin:         0 30px;
    @media only screen and (max-width: 991.98px) { 
        display:    none;
    } 
}