body {
    font-family: 'Montserrat', sans-serif;
}
.bg-dark {
    background: #313139 !important;
}

:not(pre)>code[class*=language-], pre[class*=language-] {
    background:     #5a5d7a !important;
}

code[class*=language-],pre[class*=language-] {
    color:            #ededed;
    font-family:        Consolas,Monaco,'Andale Mono','Ubuntu Mono',monospace;
    text-shadow:        none !important;
    text-align:         left !important;
    white-space:        pre-wrap !important;
    word-spacing:       normal !important;
    word-break:         normal !important;
    word-wrap:          normal !important;
    line-height:        1.5 !important;
    tab-size:           4 !important;
    hyphens:            none !important;
}
pre[class*=language-] {
    background:     #5a5d7a !important;
    border-radius:    25px !important;
    padding:          25px !important;
}
code[class*=language-] {

}
.token.function {
    color:      #c7a2a2 !important;
}
.token.string {
    color:      #a4cae6 !important;
}
.token.comment {
    color:      #acacac !important;
}
.token.keyword {
    color:      #18234a !important;
}


.fade {
    animation: fade 1.5s;
}
@keyframes fade {
    0% {
        opacity: 0.85;
    }

    100% {
        opacity: 1;
    }
}


